
import { ref, defineComponent, onMounted, computed, inject } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Skeletons from "./reservations/pages/components/Skeletons.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import MessageService from "@/core/services/MessageService";
//import { getToken  } from "firebase/messaging";
import moment from "moment";
interface ReservationDetails {
  reservaId: string;
  segmento: string;
  marca: string;
  modelo: string;
}

export default defineComponent({
  name: "dashboard",
  components: {Skeletons},
  inject: ['messaging'],
  setup() {
    const router = useRouter();
    const store = useStore();
    const flotaActual = ref<ReservationDetails>();
    const visibleBottom = ref(false);
    const vapidKey = MessageService.getVapidKey();
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    if(vapidKey!=null){
        store
          .dispatch(Actions.UPDATE_USER, {id:currentUser.value.email, vapidKey, esApp:false});
    } /*else {
      const messaging = inject('messaging') as any;
      const token = ref();
      getToken(messaging, { vapidKey: process.env.VUE_APP_GCM_ID })
      .then((currentToken) => {
        if (currentToken) {
          token.value = currentToken;
          store
          .dispatch(Actions.UPDATE_USER, {id:currentUser.value.email, vapidKey:token.value, esApp:false});
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    }*/
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerReservaProxima();
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: "splash" });
      }
    });
    
    const reserva = computed(() => {
      return store.getters.currentReserva;
    });
    const loading = ref(true);
    const obtenerReservaProxima = () => {
      store
        .dispatch(Actions.GET_RESERVA_PROXIMA, currentUser.value.driverId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };
    const formatDate = (value) => {
      return moment(value).format("MMM D YYYY");
    };
    const formatHour = (value) => {
      return moment(value).format("LT");
    };
    const formatCurrency = (value) => {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    };
    const imagen = (flota)=>{
      if(flota.urlImagen && flota.urlImagen!='')
         return flota.urlImagen;
      return `/media/flota/${flota.segmentoVehiculo}/${flota.marcaVehiculo}/${flota.modeloVehiculo.replace('/','-')}.png`;
    };


    const imageUrlAlt = (event) => {
      event.target.src = "/media/mock/auto3.png";
    };

    const targetReserva = (reserva) => {
      return `#${reserva.reservaId}`;
    };

    const bgBadge = (reserva) => {
      return reserva.codigoEstado > 0 ? "bg-success" : "bg-info";
    };
    const verFlota = (flota) => {
      visibleBottom.value = true;
      flotaActual.value = flota;
    };

    return {
      loading,
      currentUser,
      reserva,
      formatDate,
      formatCurrency,
      formatHour,
      imagen,
      imageUrlAlt,
      targetReserva,
      bgBadge,
      visibleBottom,
      flotaActual,
      verFlota,
      moment,
    };
  },
});
